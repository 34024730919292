<template>
  <v-card
    style="text-align: center"
    :flat="flat == undefined || flat == false ? false : true"
  >
    <v-card-text>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="playing ? pause() : play()"
        :disabled="!loaded"
      >
        <v-icon v-if="!playing || paused">mdi-play</v-icon>
        <v-icon v-else>mdi-pause</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="stop()"
        :disabled="!loaded"
      >
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="mute()"
        :disabled="!loaded"
      >
        <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-mute</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="loaded ? download() : reload()"
        v-if="!loaded"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="loaded ? download() : reload()"
        v-if="loaded && downloadable"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-progress-linear
        v-model="percentage"
        height="5"
        style="margin-top: 15px; margin-bottom: 15px"
        @click.native="setPosition()"
        :disabled="!loaded"
      ></v-progress-linear>
      <p>{{ currentTime }} / {{ duration }}</p>
    </v-card-text>
    <audio
      id="player"
      ref="player"
      v-on:ended="ended"
      v-on:canplay="canPlay"
      :src="file"
    ></audio>
    <div
      ref="lyrics"
      :class="'lyrics-karaoke-' + indexComponent"
      class="lyrics-karaoke mb-7"
    >
      <template v-for="(lyric, index) in lyrics">
        <span
          class="word-spacing-karaoke cursor-pointer"
          :key="index"
          @click="openDialogWord(index)"
          >{{ lyric.word }}</span
        >
      </template>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogWord"
        persistent
        max-width="700px"
        scrollable
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Edit</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogWord = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Word:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="word_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Start Time (giây):</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0"
                      ><v-text-field
                        dense
                        v-model="start_time_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">End Time (giây):</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0"
                      ><v-text-field
                        dense
                        v-model="end_time_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogWord = false"
              >Đóng</v-btn
            >
            <v-btn
              outlined
              class="font-weight-bold"
              color="blue darken-1"
              text
              @click="editWord"
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>
<script>
const formatTime = (second) =>
  new Date(second * 1000).toISOString().substr(11, 8);

export default {
  name: "vuetify-karaoke",
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: null,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    indexComponent: {
      type: Number,
      default: 0,
    },
    lyrics: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00:00:00",
      audio: undefined,
      totalDuration: 0,
      secondPlaying: 0,
      dialogWord: false,
      word_input: "",
      index_word: null,
      start_time_input: null,
      end_time_input: null,
    };
  },
  watch: {
    secondPlaying(val) {
      this.highlightTextLyric(val);
    },
    dialogWord(val) {
      if (!val) {
        this.index_word = null;
        this.word_input = "";
        this.start_time_input = null;
        this.end_time_input = null;
      }
    },
  },
  methods: {
    setPosition() {
      this.audio.currentTime = parseInt(
        (this.audio.duration / 100) * this.percentage
      );
    },
    stop() {
      this.audio.pause();
      this.paused = true;
      this.playing = false;
      this.audio.currentTime = 0;
    },
    play() {
      if (this.playing) return;
      this.audio.play().then(() => (this.playing = true));
      this.paused = false;
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    download() {
      this.audio.pause();
      window.open(this.file, "download");
    },
    mute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
      this.volumeValue = this.isMuted ? 0 : 75;
    },
    reload() {
      this.audio.load();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }

        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    _handlePlayingUI: function () {
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.secondPlaying = this.audio.currentTime;
      this.currentTime = formatTime(this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function (e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    // addLyricsInDom() {
    //   let divLyrics = this.$refs.lyrics;
    //   let vm = this;
    //   this.lyrics.map((item, i) => {
    //     let elemInner = document.createElement('span');
    //     elemInner.classList.add('word-spacing-karaoke');
    //     let textToAdd = document.createTextNode(vm.lyrics[i].word);
    //     elemInner.appendChild(textToAdd);
    //     divLyrics.append(elemInner);
    //   })
    // },
    highlightTextLyric(currentTime) {
      let divLyrics = document.getElementsByClassName(
        "lyrics-karaoke-" + this.indexComponent
      );
      for (let iLy = 0; iLy < this.lyrics.length; iLy++) {
        if (
          currentTime >= this.lyrics[iLy].startTime &&
          currentTime <= this.lyrics[iLy].endTime
        ) {
          divLyrics[0].children[iLy].classList.add("highlight-lyric");
          // if (this.lyrics[iLy].endTime >= currentTime && iLy > 0) {
          //   divLyrics[0].children[iLy - 1].classList.remove("highlight-lyric");
          // }
        }
      }
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
      // this.addLyricsInDom();
    },
    openDialogWord(indexWord) {
      this.index_word = indexWord;
      this.word_input = this.lyrics[indexWord].word;
      this.start_time_input = this.lyrics[indexWord].startTime;
      this.end_time_input = this.lyrics[indexWord].endTime;
      this.dialogWord = true;
    },
    editWord() {
      let flat = false;
      if (!this.word_input) {
        this.$toasted.error("Word không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = true;
      }
      if (
        this.start_time_input === undefined ||
        this.start_time_input == null ||
        this.start_time_input === ""
      ) {
        this.$toasted.error("Start time không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = true;
      }
      if (
        this.end_time_input === undefined ||
        this.end_time_input == null ||
        this.end_time_input === ""
      ) {
        this.$toasted.error("End time không được bỏ trống !", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = true;
      }
      if (flat) return;

      this.lyrics[this.index_word].word = this.word_input;
      this.lyrics[this.index_word].startTime = this.start_time_input;
      this.lyrics[this.index_word].endTime = this.end_time_input;
      this.dialogWord = false;
    },
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
};
</script>

<style lang="scss">
.highlight-lyric {
  color: rgba(33, 150, 243, 0.9215686274509803);
}
.lyrics-karaoke {
  text-align: justify;
  padding: 0 15px;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.8;
}
.word-spacing-karaoke {
  font-size: 15px;
  display: inline-block;
  margin-left: 7px;
  &:first-child {
    text-transform: capitalize;
    margin-left: 35px;
  }
}
</style>
